import { useTheme } from '@material-ui/core'
import Konva from 'konva'
import log from 'loglevel'
import React from 'react'
import { Group, Rect, Shape, Text } from 'react-konva'
import { useCallbackRef } from 'use-callback-ref'
import { StreamVideosMap } from '../../../api/Api'
import { VideoArchiveDate } from '../../../api/ProjectDetailsExtended'
import { StreamExtended } from '../../../api/StreamExtended'
import { VideoSummaryExtended } from '../../../api/VideoSummaryExtended'
import { darkTheme } from '../../../styles/theme'
import { useApi } from '../../ApiContext'
import { useProject } from '../../ProjectWrapper'
import { getUseVODStore } from '../VODController'
import { useUseTimelineStore } from './Timeline'
import { TimelineCollapsibleSection } from './TimelineCollapsibleSection'
import { TimelineRow } from './TimelineRow'

export const TimelineStreams = ({
  rowGroupIndex,
}: {
  rowGroupIndex: number
}) => {
  const project = useProject()
  const useVODStore = getUseVODStore()
  const useTimelineStore = useUseTimelineStore()

  const currentStreamId = useVODStore((state) => state.videoStreamId)
  const rowHeight = useTimelineStore((state) => state.rowHeight)

  const currentStream = project.streams.find((s) => s.id === currentStreamId)

  return (
    <TimelineCollapsibleSection
      expandedTitle={'Streams'}
      collapsedTitle={currentStream?.name}
      rowGroupIndex={rowGroupIndex}
      childRowCount={project.streams.length}
    >
      {(collapsed) => (
        <Group>
          {project.streams
            .filter((s) => !collapsed || s.id === currentStreamId)
            .map((s, i) => (
              <TimelineRow key={s.id} y={i * rowHeight} i={i}>
                <Stream stream={s} />
              </TimelineRow>
            ))}
        </Group>
      )}
    </TimelineCollapsibleSection>
  )
}

const Stream = ({ stream }: { stream: StreamExtended }) => {
  const theme = useTheme()
  const project = useProject()
  const useVODStore = getUseVODStore()
  const useTimelineStore = useUseTimelineStore()

  const currentStreamId = useVODStore((state) => state.videoStreamId)

  const rowHeight = useTimelineStore((state) => state.rowHeight)
  const width = useTimelineStore((state) => state.width)
  const leftColumnWidth = useTimelineStore((state) => state.leftColumnWidth)

  const [hover, setHover] = React.useState(false)

  const textRef = useCallbackRef<Konva.Text>(null, (text) => {
    if (text) {
      text.cache()
    }
  })

  return (
    <Group
      x={0}
      y={0}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Group
        clip={{
          x: 0,
          y: 0,
          width: leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={0}
          y={0}
          width={leftColumnWidth}
          height={rowHeight}
          onClick={(e) => {
            const { gotoVideo } = useVODStore.getState()
            gotoVideo({
              streamId: stream.id,
            })
          }}
        />
        <Text
          ref={textRef}
          text={stream.name}
          fill={theme.palette.text.primary}
          x={3}
          y={3}
          fontSize={13}
        />
      </Group>
      <Group
        clip={{
          x: leftColumnWidth,
          y: 0,
          width: width - leftColumnWidth,
          height: rowHeight,
        }}
      >
        <Rect
          x={leftColumnWidth}
          y={0}
          width={width - leftColumnWidth}
          height={rowHeight}
          onClick={(e) => {
            const { gotoVideo } = useVODStore.getState()
            gotoVideo({
              streamId: stream.id,
              dateTime: useTimelineStore
                .getState()
                .mouseDateTime?.setZone(project.timezone, {
                  keepLocalTime: true,
                }),
            })
          }}
        />
        <Days stream={stream} />
      </Group>
      {(hover || currentStreamId === stream.id) && (
        <Rect
          x={0}
          y={0}
          width={width}
          height={rowHeight}
          fill={`rgba(128, 128, 128, 0.3)`}
          listening={false}
        />
      )}
    </Group>
  )
}

const Days = React.memo(({ stream }: { stream: StreamExtended }) => {
  const project = useProject()

  return (
    <Group listening={false}>
      {project.videoArchiveDates
        .filter((vad) => vad.streamIds.indexOf(stream.id) > -1)
        .map((vad) => (
          <DayBlock key={vad.dateString} vad={vad} stream={stream} />
        ))}
    </Group>
  )
})

Days.displayName = 'Days'

// custom shape is faster than node for every hour
// TODO: combine all days into one custom shape
const DayBlock = React.memo(
  ({ vad, stream }: { vad: VideoArchiveDate; stream: StreamExtended }) => {
    const theme = useTheme()
    const api = useApi()
    const project = useProject()
    const useTimelineStore = useUseTimelineStore()

    const stateRef = React.useRef<{
      videos: VideoSummaryExtended[]
      videosStabilized: VideoSummaryExtended[]
      videosLoading: boolean
    }>({
      videos: [],
      videosStabilized: [],
      videosLoading: false,
    })

    const groupRef = useCallbackRef<Konva.Group>(null, (group) => {
      group?.getStage()?.batchDraw()
    })

    const loadVideos = React.useCallback(
      async function loadVideos() {
        // log.debug(`load videos for ${vad.dateString}`)
        let allVideos: StreamVideosMap | undefined

        allVideos = api.queryClient.getQueryData<StreamVideosMap>([
          stream.project.name,
          vad.dateString,
        ])

        if (!allVideos) {
          stateRef.current.videosLoading = true
          allVideos = await api.getvideosByDate(
            project,
            vad.startDateTime.setZone(project.timezone, {
              keepLocalTime: true,
            })
          )
        }

        const videos1x = allVideos.get(stream.id)?.get('200x')
        const videos4x = allVideos.get(stream.id)?.get('4x-stab')

        if (videos1x) {
          stateRef.current.videos = videos1x
          if (videos4x) {
            stateRef.current.videosStabilized = videos4x
          }
          groupRef.current?.getLayer()?.batchDraw()
        } else {
          log.error('No videos loaded for day')
        }
      },
      [useTimelineStore, api]
    )

    return (
      <Group ref={groupRef} listening={false}>
        <Shape
          perfectDrawEnabled={false}
          sceneFunc={function renderDayblock(context, shape) {
            // get Timeline state
            const {
              width,
              rowHeight,
              leftColumnWidth,
              currentScale,
            } = useTimelineStore.getState()

            // calculate day width
            const x = Math.floor(currentScale(vad.systemStartDateTime))
            const x1 = Math.floor(currentScale(vad.systemEndDateTime))
            const dayWidth = x1 - x

            // determine if shoud be visible in the viewport
            const visible = x < width && x1 > leftColumnWidth

            context.beginPath()

            // day rect
            if (visible) {
              if (dayWidth < 200) {
                context.rect(x, 0, x1 - x, rowHeight - 1)
              } else {
                if (
                  !stateRef.current.videos.length &&
                  !stateRef.current.videosLoading
                ) {
                  loadVideos()
                }
                // size the hour rects for current scale
                stateRef.current.videos.forEach(function renderHourBlock(
                  video
                ) {
                  const startX = currentScale(video.systemStartDateTime)
                  const endX = currentScale(video.systemEndDateTime)

                  context.rect(startX, 0, endX - startX, rowHeight - 1)
                })
              }
            }

            context.closePath()
            // (!) Konva specific method, it is very important
            context.fillShape(shape)
          }}
          fill={theme.palette.success.main}
        />
        {/* {stateRef.current.videosStabilized.length && ( */}
        <Shape
          perfectDrawEnabled={false}
          sceneFunc={function renderDayblock(context, shape) {
            // get Timeline state
            const {
              width,
              rowHeight,
              leftColumnWidth,
              currentScale,
            } = useTimelineStore.getState()

            // calculate day width
            const x = Math.floor(currentScale(vad.systemStartDateTime))
            const x1 = Math.floor(currentScale(vad.systemEndDateTime))
            const dayWidth = x1 - x

            // determine if shoud be visible in the viewport
            const visible = x < width && x1 > leftColumnWidth

            context.beginPath()

            // day rect
            if (visible && stateRef.current.videosStabilized.length) {
              if (dayWidth < 200) {
                context.rect(x, 0, x1 - x, rowHeight - 1)
              } else {
                // size the hour rects for current scale
                stateRef.current.videosStabilized.forEach(
                  function renderHourBlock(video) {
                    const startX = currentScale(video.systemStartDateTime)
                    const endX = currentScale(video.systemEndDateTime)

                    context.rect(startX, 0, endX - startX, rowHeight - 1)
                  }
                )
              }
            }

            context.closePath()
            // (!) Konva specific method, it is very important
            context.fillShape(shape)
          }}
          fill={darkTheme.custom.chartColorsCategorical[1]}
        />
        {/* )} */}
      </Group>
    )
  }
)
