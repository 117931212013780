import log from 'loglevel'
import { DateTime } from 'luxon'
import React, { createRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useAsync } from 'react-use'
import zustand, { UseStore } from 'zustand'
import { XYWH } from '../../../types'
import { VideoSummaryStateEnum } from '../../api/codegen/typescript-axios'
import { VideoSummaryExtended } from '../../api/VideoSummaryExtended'
import { checkTimeZone } from '../../helpers/checkTimeZone'
import { createCtx } from '../../helpers/createCtx'
import { validateXYWH } from '../../helpers/validateXYWH'
import { useAnimationFrame } from '../../hooks/useAnimationFrame'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'

interface GotoVideoOptions {
  streamId?: number
  dateTime?: DateTime | null
  speed?: number
  endDateTime?: DateTime
  play?: boolean
  skipUnarchiveModal?: boolean
  goToNearestTime?: boolean
  crop?: XYWH
}

export const vodQueryKeys: Record<keyof GotoVideoOptions, string> = {
  dateTime: 'dateTime',
  endDateTime: 'endDateTime',
  streamId: 'streamId',
  speed: 'speed',
  play: 'play',
  skipUnarchiveModal: 'skipUnarchiveModal',
  goToNearestTime: 'goToNearestTime',
  crop: 'crop',
}

export type GotoVideo = (options: GotoVideoOptions) => void

export interface Alert {
  severity: 'success' | 'error'
  message: string
}

export type VODState = {
  videoRef: React.RefObject<HTMLVideoElement>
  video?: VideoSummaryExtended
  preloadVideo?: VideoSummaryExtended
  videoDateTime: DateTime
  videoMinute: DateTime
  videoHour: DateTime
  videoDate: DateTime
  endDateTime: DateTime | undefined
  dateTimeRange?: [DateTime, DateTime]
  videoPlaying: boolean
  videoStreamId?: number
  speed: number
  preferStabilized: boolean
  zoomMode: boolean
  crop?: XYWH
  videoViewport: XYWH
  alert?: Alert
  loadingVideo: boolean
  gotoVideo: (opts: GotoVideoOptions) => void
  _gotoVideo: (opts: GotoVideoOptions) => Promise<void>
  gotoNextVideo: () => void
}

export type UseVODStore = UseStore<VODState>

export const [getUseVODStore, VODContext] = createCtx<UseVODStore>()

export const VODController: React.FC = ({ children }) => {
  const api = useApi()
  const project = useProject()
  const searchParamsRef = React.useRef<URLSearchParams>()
  const [searchParams, setSearchParams] = useSearchParams()
  searchParamsRef.current = searchParams
  const acp = useAlertConfirmPrompt()

  let recentDateTime = DateTime.now()
    .startOf('hour')
    .minus({ minutes: 59 })
    .setZone(project.timezone)

  const dateTimeParam = searchParams.get(vodQueryKeys.dateTime)

  if (dateTimeParam) {
    try {
      recentDateTime = DateTime.fromISO(dateTimeParam, {
        zone: project.timezone,
      })
    } catch (err) {
      acp.alert({ title: 'datetime is not a valid Date string' })
    }
  }

  const [useVODStore] = useState(() => {
    return zustand<VODState>((set, get) => ({
      videoRef: createRef<HTMLVideoElement>(),
      video: undefined,
      preloadVideo: undefined,
      videoDateTime: recentDateTime,
      videoMinute: recentDateTime.startOf('minute'),
      videoHour: recentDateTime.startOf('hour'),
      videoDate: recentDateTime.startOf('day'),
      endDateTime: undefined,
      dateTimeRange: undefined,
      videoPlaying: false,
      videoStreamId: project.streams[0].id,
      speed: 1,
      preferStabilized: true,
      zoomMode: false,
      crop: undefined,
      videoViewport: { x: 0, y: 0, w: 0, h: 0 },
      alert: undefined,
      loadingVideo: false,
      gotoVideo: async (opts: GotoVideoOptions) => {
        const searchParams = searchParamsRef.current || new URLSearchParams()
        Object.entries(opts).forEach(([key, value]) => {
          if (value instanceof DateTime) {
            searchParams.set(
              key,
              value
                // HACK! allow milliseconds as a way to force seeking back to ~current URL time, like a cachebuster
                // .set({ millisecond: 0 })
                .toISO({ suppressMilliseconds: true })
            )
          } else if (
            typeof value === 'object' &&
            'x' in value &&
            'y' in value
          ) {
            // crop
            searchParams.set(key, JSON.stringify(value))
          } else if (typeof value !== 'undefined') {
            searchParams.set(key, value.toString())
          }
        })
        if (!opts.dateTime) {
          searchParams.set(
            vodQueryKeys.dateTime,
            get()
              .videoDateTime.startOf('second')
              .toISO({ suppressMilliseconds: true })
          )
        }
        setSearchParams(searchParams, { replace: true })
      },
      _gotoVideo: async ({
        streamId,
        dateTime,
        speed,
        endDateTime,
        play,
        crop,
        skipUnarchiveModal = false,
        goToNearestTime,
      }: GotoVideoOptions) => {
        console.log('GotoVideo Options:')
        console.table({
          streamId,
          dateTime: dateTime?.toISO(),
          endDateTime: endDateTime?.toISO(),
          speed,
          play,
          crop,
          skipUnarchiveModal,
          goToNearestTime,
        })
        const currentState = useVODStore.getState()

        const _streamId =
          streamId ||
          currentState.video?.stream.id ||
          currentState.videoStreamId ||
          project.streams[0].id

        checkTimeZone(project, dateTime)

        const _dateTime =
          dateTime || currentState.videoDateTime || recentDateTime

        set({
          videoStreamId: _streamId,
          videoDateTime: _dateTime,
          videoDate: _dateTime.startOf('day'),
          videoHour: _dateTime.startOf('hour'),
          loadingVideo: true,
        })

        const _speed = speed || currentState.speed || 1

        const _video = await api.getVideo({
          project,
          streamId: _streamId,
          dateTime: _dateTime,
          speed: _speed,
          goToNearestTime: !!goToNearestTime,
          preferStabilized: currentState.preferStabilized,
        })

        if (_video) {
          console.log('GotoVideo video found:')
          console.table({
            streamId: _streamId,
            dateTime: _dateTime.toISO(),
            endDateTime: endDateTime && endDateTime.toISO(),
            speed: _speed,
            videoUrl: _video?.url,
            crop: crop && Object.values(crop).join(','),
          })
        } else {
          console.log('GotoVideo no video found')
        }

        if (!_video) {
          // no video available at any speed. Show error
          log.error('No video found for this stream at this time!')
          if (videoRef.current) videoRef.current.src = ''
          useVODStore.setState({
            video: undefined,
            videoDateTime: _dateTime,
            videoStreamId: _streamId,
            alert: {
              severity: 'error',
              message: 'No video found for this stream at this time',
            },
          })
        } else {
          // There is a video but...
          switch (_video.state) {
            case VideoSummaryStateEnum.Archived:
              // it's archived. Ask to unarchive then go to a faster speed

              try {
                if (!skipUnarchiveModal) {
                  await acp.confirm({
                    title:
                      'This video not available for immediate playback at your chosen speed.',
                    description:
                      'This speed of the video has been archived in long term storage. Would you like to request it to be made available for viewing?',
                    yesText: 'Yes, unarchive the 1x',
                    noText: 'No, just show me the 20x',
                  })
                  const response = await api.videosApi.streamsVideosRequestRestore(
                    {
                      id: _video.id.toString(),
                      streamId: _video.stream.id.toString(),
                      data: {
                        project_id: _video.project.id,
                      },
                    }
                  )
                  _video.state = VideoSummaryStateEnum.Unarchiving
                  await acp.alert({
                    title:
                      response.status === 202
                        ? "The video will be restored, but it will take sometime. You will receive an email when it's ready"
                        : 'There was an error. Contact TopDeck support for help.',
                  })
                }
              } catch {
              } finally {
                useVODStore.getState().gotoVideo({
                  streamId,
                  dateTime,
                  speed: 20,
                  endDateTime,
                  play,
                  crop,
                  goToNearestTime: true,
                })
              }
              break
            case VideoSummaryStateEnum.Unarchiving:
              // it's in the unarchiving process. Go to 200x
              await acp.alert({
                title:
                  'This video is in the process of being restored from long term storage.',
              })
              useVODStore.getState().gotoVideo({
                streamId,
                dateTime,
                speed: 20,
                endDateTime,
                play,
                crop,
              })
              break
            default:
              // It's not archived and ready to play
              if (videoRef.current) {
                const _currentTime =
                  _dateTime.diff(_video.localStartDateTime).toMillis() /
                  1000 /
                  _video.speed

                const desiredPlaybackRate = _speed / _video.speed
                const minPlaybackRate = 0.1
                const maxPlaybackRate = 16

                const _playbackRate = Math.max(
                  minPlaybackRate,
                  Math.min(maxPlaybackRate, desiredPlaybackRate)
                )

                const actualSpeed: number = _video.speed * _playbackRate

                useVODStore.setState({
                  videoStreamId: _streamId,
                  speed: actualSpeed,
                  alert: undefined,
                })

                if (videoRef.current.src !== _video.url) {
                  // loading new video -> need to set all attributes
                  log.debug('Changing video src')
                  const videoEl = videoRef.current
                  videoEl.src = _video.url
                  videoEl.playbackRate = _playbackRate

                  // Try to set currentTime immediately. This works on Chrome
                  videoEl.currentTime = _currentTime

                  // But on Safari, need to wait for load to set currenttime
                  const setTime = () => {
                    if (videoEl) {
                      videoEl.currentTime = _currentTime
                      videoEl.removeEventListener('loadedmetadata', setTime)
                    }
                  }
                  videoEl.addEventListener('loadedmetadata', setTime)

                  useVODStore.setState({ video: _video })
                } else if (dateTime) {
                  // seeking within same video
                  log.debug('seeking within same video: ', _currentTime)
                  videoRef.current.currentTime = _currentTime
                  videoRef.current.playbackRate = _playbackRate
                } else {
                  // changing playbackRate of same video
                  log.debug(
                    'changing playbackRate of same video: ',
                    _playbackRate
                  )
                  try {
                    videoRef.current.playbackRate = _playbackRate
                  } catch (error) {
                    // TODO: this is usually caused by lack of speedup video
                    // find a way to warn user that speedup does not exist
                    log.error(error)
                  }
                }
              }
          }
        }

        if (play && videoRef.current) {
          try {
            videoRef.current.play()
          } catch (err) {
            console.error(err)
          }
        }

        useVODStore.setState({
          // this is not conditional because we want to
          // reset to unndefined if not specified
          endDateTime: endDateTime,
          crop: crop,
          loadingVideo: false,
        })
      },

      // when a video file ends, load the next video
      gotoNextVideo: () =>
        set((state) => {
          if (state.video) {
            state.gotoVideo({
              dateTime: state.video.localEndDateTime.plus({ seconds: 3 }),
              goToNearestTime: true,
              endDateTime: state.endDateTime,
            })
          }
          return { video: state.preloadVideo }
        }),
    }))
  })

  const videoRef = useVODStore((state) => state.videoRef)
  const preloadVideo = useVODStore((state) => state.preloadVideo)

  // handle URL updates
  useAsync(async () => {
    // keep parsing 'date' for backwards compatibility
    const dateTimeParam =
      searchParams.get(vodQueryKeys.dateTime) || searchParams.get('date')
    const endDateTimeParam = searchParams.get(vodQueryKeys.endDateTime)
    const streamParam =
      searchParams.get(vodQueryKeys.streamId) || searchParams.get('stream')
    const speedParam = searchParams.get(vodQueryKeys.speed)
    const playParam = searchParams.get(vodQueryKeys.play)
    const skipUnarchiveModal = searchParams.get(vodQueryKeys.skipUnarchiveModal)
    const goToNearestTime = searchParams.get(vodQueryKeys.goToNearestTime)
    const crop = searchParams.get(vodQueryKeys.crop)

    const goToVideoOptions: GotoVideoOptions = {
      streamId: streamParam ? parseInt(streamParam) : undefined,
      speed: speedParam ? parseInt(speedParam) : undefined,
      play: playParam ? Boolean(playParam) : true,
      skipUnarchiveModal: skipUnarchiveModal
        ? Boolean(skipUnarchiveModal)
        : false,
      goToNearestTime: goToNearestTime === 'false' ? false : true,
      crop: crop ? validateXYWH(JSON.parse(crop)) : undefined,
    }

    if (dateTimeParam) {
      // set to search param if present
      try {
        const dateTime = DateTime.fromISO(dateTimeParam, {
          zone: project.timezone,
        })
        goToVideoOptions.dateTime = dateTime
      } catch (err) {
        acp.alert({ title: 'datetime is not a valid Date string' })
      }
    } else {
      // or play newest video from API
      // const videos = await api.getvideosByDate(project, dateTime)
      // const streamVideos = videos.get(streamId)?.get('1x')
      // dateTime = streamVideos
      //   ? streamVideos[streamVideos?.length - 1].localStartDateTime
      //   : dateTime
    }

    if (endDateTimeParam) {
      // set to search param if present
      try {
        const endDateTime = DateTime.fromISO(endDateTimeParam, {
          zone: project.timezone,
        })
        goToVideoOptions.endDateTime = endDateTime
      } catch (err) {
        acp.alert({ title: 'enddatetime is not a valid Date string' })
      }
    }

    useVODStore.getState()._gotoVideo(goToVideoOptions)
  }, [searchParams])

  // update videoDateTime, videoHour, videoDate
  useAnimationFrame(function rafVODController() {
    const {
      video,
      videoDate,
      videoMinute,
      videoHour,
      endDateTime,
      loadingVideo,
    } = useVODStore.getState()

    // Don't update clocks from video if we're currently loading a new video
    // This can confuse components who depend on these clocks
    if (!video || !videoRef.current || loadingVideo) return

    const newVideoDateTime = video.localStartDateTime.plus({
      milliseconds: videoRef.current.currentTime * 1000 * video.speed,
    })

    // Update world clock
    useVODStore.setState({
      videoDateTime: newVideoDateTime,
    })

    // Maybe update minute
    if (!newVideoDateTime.hasSame(videoMinute, 'minute')) {
      useVODStore.setState({
        videoMinute: newVideoDateTime.startOf('minute'),
      })
    }

    // Maybe update hour
    if (!newVideoDateTime.hasSame(videoHour, 'hour')) {
      useVODStore.setState({
        videoHour: newVideoDateTime.startOf('hour'),
      })
    }

    // Maybe update date (day only)
    if (!newVideoDateTime.hasSame(videoDate, 'day')) {
      useVODStore.setState({
        videoDate: newVideoDateTime.startOf('day'),
      })
    }

    // Stop video and endDateTime (end of clip)
    if (endDateTime && newVideoDateTime > endDateTime) {
      try {
        videoRef.current.pause()
      } catch (err) {
        console.error(err)
      }
      useVODStore.setState({ endDateTime: undefined })
    }

    // Preload next hour
    // TODO: reimplement this when we get it a better handle on how to transition
    // if (videoRef.current.duration - videoRef.current.currentTime < 10) {
    //   throttle(
    //     () =>
    //       api
    //         .getVideo({
    //           project,
    //           streamId: video.stream.id,
    //           dateTime: video.localStartDateTime.plus({ hours: 1 }),
    //           speed,
    //           goToNearestTime: false,
    //         })
    //         .then((nextVideo) => {
    //           if (nextVideo !== preloadVideo) {
    //             useVODStore.setState({ preloadVideo: nextVideo })
    //           }
    //         }),
    //     1000
    //   )
    // }
  })

  return (
    <VODContext.Provider value={useVODStore}>
      {/* <video
        className="preloadVideo"
        src={preloadVideo?.url}
        preload="auto"
        style={{ display: 'none' }}
      /> */}
      {children}
    </VODContext.Provider>
  )
}
