import { CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import React, { useState } from 'react'
import { StreamExtended } from '../../api/StreamExtended'
import { useAlertConfirmPrompt } from '../AlertConfirmPrompt'
import { useApi } from '../ApiContext'
import { useProject } from '../ProjectWrapper'

interface PhotosphereButtonProps {
  stream: StreamExtended
  size?: 'small' | 'medium'
}

export const PhotosphereButton: React.FC<PhotosphereButtonProps> = ({
  stream,
  size = 'small',
}) => {
  const api = useApi()
  const { alert, confirm } = useAlertConfirmPrompt()
  const project = useProject()
  const [requestingPhotosphere, setRequestingPhotosphere] = useState(false)

  // Check if user has permission
  const hasPermission = project?.user_attrs?.photosphere_on_demand_permission

  if (!hasPermission) {
    return null
  }

  const handleRequestPhotosphere = async () => {
    if (!stream.id) return

    try {
      // Show confirmation dialog first
      await confirm({
        title: 'Confirm Photosphere Capture',
        description:
          'This will initiate a photosphere scan that may take up to 10 minutes to complete. During this time, the camera will rotate to capture the entire scene. Do you want to continue?',
        yesText: 'Capture',
        noText: 'Cancel',
      })

      try {
        setRequestingPhotosphere(true)
        await api.photospheresApi.streamsDemandPhotosphereScanCreate({
          streamId: stream.id.toString(),
        })
        alert({
          title: 'Success',
          description: 'Photosphere scan requested successfully.',
        })
      } catch (error) {
        console.error('Error requesting photosphere:', error)
        alert({
          title: 'Error',
          description:
            error instanceof Error
              ? error.message
              : 'Failed to request photosphere scan.',
        })
      } finally {
        setRequestingPhotosphere(false)
      }
    } catch (error) {}
  }

  return (
    <Tooltip title="Capture Photosphere">
      <span>
        <IconButton
          size={size}
          onClick={handleRequestPhotosphere}
          disabled={requestingPhotosphere}
        >
          {requestingPhotosphere ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <PhotoCameraIcon />
          )}
        </IconButton>
      </span>
    </Tooltip>
  )
}
