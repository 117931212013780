import { useEffect, useRef, useState } from 'react'
import useScript from 'react-script-hook'
import { useUnmount } from 'react-use'
import { Stream } from '../api/codegen/typescript-axios'
import { useMuxBaseConfig } from '../hooks/useMuxBaseConfig'
import { WebRTCAdaptor } from '@antmedia/webrtc_adaptor'

import { Loading } from './Loading'
// @ts-ignore
import mux from 'mux-embed'
import { useProject } from './ProjectWrapper'
import log from 'loglevel'

export const AntMediaWebRTCPlayer = ({
  stream,
  setError,
}: {
  stream: Stream
  setError?: (e: string) => void
}) => {
  const muxBaseConfig = useMuxBaseConfig()

  const project = useProject()

  const rtcRef = useRef(null)

  const [adaptor, setAdaptor] = useState<WebRTCAdaptor>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (!adaptor && rtcRef.current && stream.ant_id) {
      const webRTCAdaptor = new WebRTCAdaptor({
        websocket_url: 'wss://antmedia.topdeck.ai/WebRTCAppEE/websocket',
        remoteVideoId: 'webrtcvideo',
        isPlayMode: true,
        debug: false,
        callback: (info: any, description: any) => {
          switch (info) {
            case 'initialized':
              log.info('initialized: ' + JSON.stringify(info))
              webRTCAdaptor.play(stream.ant_id!)
              break
            case 'play_started':
              setLoading(false)
              log.info('play started')
              break
            case 'closed': // (!)
              log.info('Connecton closed: ' + JSON.stringify(info))
              break
          }
        },
        callbackError: (error: any) => {
          // console.error(error)
          // setError &&
          //   setError(
          //     'There was a problem loading the low-latency feed: ' +
          //       JSON.stringify(error)
          //   )
          //some of the possible errors, NotFoundError, SecurityError,PermissionDeniedError
          // console.log("error callback: " + JSON.stringify(error));
        },
      })

      mux.monitor(rtcRef.current, {
        debug: false,
        data: {
          ...muxBaseConfig,

          player_name: 'AntMediaWebRTCPlayer', // any arbitrary string you want to use to identify this player

          // Video Metadata
          video_id: stream.ant_id, // ex: 'abcd123'
          video_title: `${project.slug} - ${stream.name} - ANT`, // ex: 'My Great Video'
          video_stream_type: 'live low-latency', // 'live' or 'on-demand',
          video_content_type: 'live video',
        },
      })

      setAdaptor(webRTCAdaptor)
    }
  }, [adaptor, stream.ant_id])

  useUnmount(() => {
    if (adaptor) {
      adaptor.stop(stream.ant_id!)
      adaptor.closeStream()
      adaptor.closeWebSocket()
    }
  })

  return (
    <>
      <video
        ref={rtcRef}
        id="webrtcvideo"
        autoPlay
        playsInline
        muted
        style={{
          display: 'block',
          width: '100%',
          height: '100%',
          backgroundColor: '#000',
        }}
      ></video>
      {loading && (
        <Loading
          text="Connecting directly to camera…"
          style={{ color: '#fff' }}
        ></Loading>
      )}
    </>
  )
}
